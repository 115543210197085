{#if field.term}
<div class="its-plans__topic-term-container">
    <p class="its-plans__topic-term">{termDisplayName}</p>
    {#if canEdit}
    <PlainButton ariaLabel={removeLabel} text={i18n.remove} type="delete" on:click={removeTerm} />
    <PlainButton ariaLabel={changeLabel} text={i18n.change} type="change" on:click={openChangeTermModal} />
    {/if}
</div>
{:else if canEdit}
    <PlainButton ariaLabel={addLabel} text={i18n.add} type="add" on:click={openAddTermModal} />
{/if}

<AddTermModal
    selectedTermId={selectedTermId}
    termTitle={field.title}
    type={addTermModalType}
    bind:enabled={addTermModalEnabled}
    on:close={closeAddTermModal}
    on:confirm={updateTerm}
/>

<script>
    import { formatLanguageString } from "@itslearning/atlas/strings/format";
    import AddTermModal from "@its-ui/planner-common/components/AddTermModal/AddTermModal.svelte";
    import PlainButton from "@its-ui/planner-common/components/Button/PlainButton.svelte";
    import { removeTopicTerm, updateTopicTerm } from "@its-ui/planner-common/api";
    import { removeTopicFieldTerm, updateTopicFieldTerm } from "@its-ui/planner-common/controllers/topicController";

    export let canEdit = false;
    export let field;
    export let i18n;

    let addTermModalEnabled = false;
    /** @type {'add'|'change'} */
    let addTermModalType = 'add';
    let selectedTermId = field.term?.id

    const termLabel = field.title.toLowerCase();
    const addLabel = formatLanguageString(i18n.addWithPlaceholder, termLabel);
    const changeLabel = formatLanguageString(i18n.changeWithPlaceholder, termLabel);
    const removeLabel = formatLanguageString(i18n.removeWithPlaceholder, termLabel);

    $: termDisplayName = field && field.term ? field.term.Text || field.term.text : "";

    const closeAddTermModal = () => addTermModalEnabled = false;
    const openChangeTermModal = () => {
        addTermModalType = 'change';
        addTermModalEnabled = true;
    }
    const openAddTermModal = () => {
        addTermModalType = 'add';
        addTermModalEnabled = true;
    }

    const updateTerm = e => {
        updateTopicTerm(e.detail.termId)
            .then(term => updateTopicFieldTerm(field.id, term));
    };
    const removeTerm = () => {
        removeTopicTerm()
            .then(() => removeTopicFieldTerm(field.id));
    };
</script>
