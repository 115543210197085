{#if showLink}
    <div class={`itsl-progess-plan-link ${containerClass} ${secondaryStandaloneClass}`}>
        <Link
            href={linkUrl}
            {iconClass}
            iconPlacement="end"
            {kind}
            on:click={handleLinkClick}
        >
            <span class="screen-reader">
                {linkScreenReaderText}
            </span>
            <span aria-hidden="true">
                {linkText}
            </span>
        </Link>
    </div>
{/if}

<script>
    import { onMount } from "svelte";
    import Link from "@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte";
    import { completePlan, uncompletePlan } from "@its-ui/planner-common/api";
    import config from "../../config";
    import {
        columnTypes,
        columnVisibility,
        onePlanAtATimePlanStatus,
        planType,
    } from "../../constants";
    import { addBackDestinationParam } from "../../helpers/backLinkHelper";
    import { postNewPlannerKpi } from "../../helpers/kpiHelper";
    import i18n from "../../i18n";

    export let backExtraParams = {};
    export let columnVisibilities = undefined;
    export let containerClass = "";
    export let courseId = undefined;
    export let iconClass = "";
    export let isPlanCard = false;
    export let kind = "primary-standalone";
    export let kpiContinue = config.kpi.kpiMeasurementContinuePlanCard;
    export let kpiStart = config.kpi.kpiMeasurementStartPlanCard;
    export let plan = undefined;
    export let startAtPlanPage = false;
    export let useGenericResourceColumn = false;
    export let useNextPlanFeature = true;

    const isComplete = plan.planStatus === onePlanAtATimePlanStatus.completed;
    let isContinue = false;
    const isLast = config.nextAvailablePlanId == null;
    let isNextPlan = false;
    const isReopened = plan.planStatus === onePlanAtATimePlanStatus.reopened;
    let isStart = true;
    let linkText = i18n.start;
    let linkUrl = "";
    let linkScreenReaderText = i18n.startPlan;
    const secondaryStandaloneClass = "";
    let showLink = false;
    const useBetterProgressionBetweenPlansWithoutDate = plan.useBetterProgressionBetweenPlansWithoutDate;

    let resourcesVisibility = config.columnVisibilities
        ? config.columnVisibilities[columnTypes.Resources]
        : undefined;
    let activitiesVisibility = config.columnVisibilities
        ? config.columnVisibilities[columnTypes.Activities]
        : undefined;
    let elementsVisibility = config.columnVisibilities
        ? config.columnVisibilities[columnTypes.Elements]
        : undefined;

    const hasElements = plan.elements.filter(e => !e.isFolder).length > 0;
    const allActivities = hasElements ? plan.elements.filter(e => !e.isResource && !e.isFolder) : [];
    const allResources = hasElements ? plan.elements.filter(e => e.isResource && !e.isFolder) : [];
    const isAllActivitiesCompleted = allActivities.every(e => e.isCompleted);
    const isAllResourcesCompleted = allResources.every(e => e.isCompleted);
    const isAllElementsCompleted = plan.elements.every(e => e.isCompleted || e.isFolder);
    const isNoResourcesCompleted = allResources.every(e => !e.isCompleted);
    const isNoActivitiesCompleted = allActivities.every(e => !e.isCompleted);

    let handleLinkClick = () => {};

    onMount(async () => {
        if((hasElements && isAllActivitiesCompleted) // Plan completed
        || (!hasElements && !isPlanCard && (config.planType == planType.deadLineDriven || config.planType == planType.allPlansAtOnce)) // Plans view, no elements, deadline-driven or all plans at once
        || (!hasElements && !isPlanCard && isLast)) { // Plans view, no elements, last plan
            handleCompletePlan();
        }

        isReopened && handleUncompletePlan();
    });

    $: canPlanBeSetAsCompleted =
        config.planType === planType.onePlanAtATime &&
        (plan.elements.length === 0 || plan.elements.every(e => e.isCompleted)) &&
        !isComplete;

    $: {
        if (resourcesVisibility === undefined && columnVisibilities) {
            resourcesVisibility = columnVisibilities[columnTypes.Resources];
            activitiesVisibility = columnVisibilities[columnTypes.Activities];
            elementsVisibility = columnVisibilities[columnTypes.Elements];
        }
    }

    $: areResourcesVisible = resourcesVisibility === columnVisibility.All;
    $: areActivitiesVisible = activitiesVisibility === columnVisibility.All;
    $: areElementsVisible = elementsVisibility === columnVisibility.All;

    $: {
        // Set link text
        let isLinkTextStart = false;
        let isLinkTextContinue = false;
        let isLinkTextNext = false;

        if (useGenericResourceColumn && areElementsVisible) {
            isLinkTextContinue = plan.elements.some(e => e.isCompleted && !e.isFolder);
            isLinkTextNext = plan.elements.every(e => e.isFolder || e.isCompleted);
        } else {
            isLinkTextContinue =
                (areResourcesVisible && !isAllResourcesCompleted) ||
                (areActivitiesVisible && !isAllActivitiesCompleted);
            isLinkTextNext =
                (!areResourcesVisible && !areActivitiesVisible) ||
                (areResourcesVisible && !areActivitiesVisible && isAllResourcesCompleted) ||
                (!areResourcesVisible && areActivitiesVisible && isAllActivitiesCompleted) ||
                (areResourcesVisible &&
                    isAllResourcesCompleted &&
                    areActivitiesVisible &&
                    isAllActivitiesCompleted);
            isLinkTextStart = areResourcesVisible && isNoResourcesCompleted && areActivitiesVisible && isNoActivitiesCompleted;
        }

        isLinkTextStart ? setLinkStart() :
            isLinkTextNext && useNextPlanFeature
                ? setLinkNextPlan()
                : isLinkTextContinue
                ? setLinkContinue()
                : setLinkStart();
    }

    $: {
        let url = "";

        if (startAtPlanPage && isStart) {
            url = addBackDestinationParam(plan.url, backExtraParams);
        } else if (isNextPlan) {
            url = addBackDestinationParam(config.nextAvailablePlanUrl, backExtraParams);
        } else {
            let nextResource = undefined;

            if (useGenericResourceColumn && areElementsVisible) {
                nextResource = plan.elements.find(e => !e.isCompleted);
            } else {
                nextResource = areResourcesVisible && !isAllResourcesCompleted
                    ? allResources.find(e => !e.isCompleted)
                    : areActivitiesVisible && !isAllActivitiesCompleted
                      ? allActivities.find(e => !e.isCompleted)
                      : undefined;
            }

            if (nextResource) {
                url = addBackDestinationParam(
                    `${plan.url}/element/${nextResource.id}`,
                    backExtraParams,
                );
            } else if (canPlanBeSetAsCompleted && plan.elements.length > 0) {
                url = addBackDestinationParam(
                    `${plan.url}/element/${plan.elements[plan.elements.length - 1].id}`,
                    backExtraParams,
                );
            }
        }

        linkUrl = url;
    }

    $: {
        showLink =
        // Plan is completed or link is next plan and plan type deadline-driven
        ((isComplete || (hasElements && isAllElementsCompleted)) && !useNextPlanFeature)
        || (isNextPlan && config.planType == planType.deadLineDriven)
            ? false
            : isStart || isContinue || (isNextPlan && config.nextAvailablePlanId);

        // Hide start/continue link for plans without resources when "Better Progression Between Plans Without Date" is off
        if (!hasElements && !useBetterProgressionBetweenPlansWithoutDate) {
            showLink = false;
        }
    }

    const setLinkStart = () => {
        linkText = i18n.start;
        linkScreenReaderText = i18n.startPlan;
        isStart = true;
        isContinue = isNextPlan = false;
        handleLinkClick = redirect(handleCourseStartOrContinue);
    };

    const setLinkContinue = () => {
        linkText = i18n.continue;
        linkScreenReaderText = i18n.continuePlan;
        isContinue = true;
        isStart = isNextPlan = false;
        handleLinkClick = redirect(handleCourseStartOrContinue);
    };

    const setLinkNextPlan = () => {
        linkText = i18n.nextPlan;
        linkScreenReaderText = i18n.nextPlan;
        isNextPlan = true;
        isStart = isContinue = false;
        handleLinkClick = redirect(handleCompletePlan);
    };

    const handleCourseStartOrContinue = async () => {
        await postNewPlannerKpi(isContinue ? kpiContinue : kpiStart);
    };

    const handleCompletePlan = async () => {
        if (!isComplete) await completePlan(courseId, plan.id);
    };

    const handleUncompletePlan = async () => {
        if (isReopened) await uncompletePlan(courseId, plan.id);
    };

    const redirect = func => async (e) => {
        e.preventDefault();
        await func();
        window.location.href = linkUrl;
    };
</script>
