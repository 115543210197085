<div {id} class="itsl-progress-bar {cssClass}">
    <label for="{id}-progress">
        <span aria-hidden={validAccessibleTextString}>{value}/{max}</span>
        <span class="screen-reader">{accessibleTextString}</span>
    </label>

    <progress
        id="{id}-progress"
        {max}
        {value}
        aria-label={accessibleTextString}
        aria-valuenow={value}
        class="itsl-progress-bar__progress {progressCssClass}"
        class:itsl-progress-bar__progress--full={value >= max}
    />
</div>

<script>
    import { formatLanguageString } from "@itslearning/atlas/strings/format";

    export let id = Math.random().toString(36).substring(2, 15);
    export let value = 0;
    export let max;
    export let cssClass = "";
    export let progressCssClass = "";
    export let accessibleText;

    $: accessibleTextString = accessibleText && formatLanguageString(accessibleText, value, max);
    $: validAccessibleTextString = accessibleTextString ? true : false;
</script>
