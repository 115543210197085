<Modal
    id="show-copy-plans-confirm-modal-id"
    bind:enabled={showCopyConfirmModal}
    titleText={i18n.copy}
    confirmText={i18n.close}
    showCancel={false}
    on:close={close}
>
    <div slot="body">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <div class="copy-plans-confirm-modal__body">{@html confirmModalBody}</div>
        <Banner visible={bannerVisible} type="warning" closable={false} html={bannerText} />
    </div>
</Modal>

<script>
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import Banner from "@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte";
    import { createEventDispatcher } from "svelte";

    export let showCopyConfirmModal;
    export let confirmModalBody;
    export let i18n;
    export let bannerVisible;
    export let bannerText;

    const dispatch = createEventDispatcher();

    function close() {
        dispatch("close");
    }
</script>
