<svelte:window
    on:click={clickHandler}
    on:keyup={onWindowKeyUp}
    on:scroll={onScrollChange}
    on:resize={updateTooltipDialogPosition}
/>

<div
    bind:this={tooltipdialog}
    id="{id}-{role}"
    class="itsl-tooltipdialog itsl-tooltipdialog-{id} itsl-tooltipdialog--{placement} {displayClass}"
    {role}
>
    <div class="itsl-tooltipdialog__arrow"></div>
    <div class="itsl-tooltipdialog__content">
        <div class="its-tooltipdialog__content-headingrow">
            <div class="its-tooltipdialog__content-headingrow-text">{heading}</div>
            <div class="itsl-tooltipdialog__content-headingrow-closebutton-container">
                <IconButton
                    htmlType="button"
                    iconClass={closeButtonClass}
                    iconOnly={true}
                    text={closeButtonText}
                    type="plain"
                    on:click={() => dispatch("close")}
                />
            </div>
        </div>
        {#if text}
            <div>{text}</div>
        {/if}
    </div>
    {#if showActions}
        <div class="itsl-tooltipdialog__footer">
            <Link
                kind="standalone"
                href={new URL(location.origin + destinationUrl)}
                text={linkText}
            />
            <IconButton
                describedby={removeButtonId}
                htmlType="button"
                iconClass="itsl-tooltipdialog__footer-removebutton"
                text={actionButtonText}
                type="plain"
                on:click={() => dispatch("delete")}
            />
            <span id={removeButtonId} class="screen-reader">
                {actionButtonLabel}
            </span>
        </div>
    {/if}
</div>

<script>
    import { Keys } from "@itslearning/atlas/keyboard/keys";
    import { normalizeKey } from "@itslearning/atlas/keyboard/normalize";
    import { guidV4 } from "@itslearning/atlas/generate/guid";
    import { createEventDispatcher } from "svelte";
    import IconButton from "@itslearning/prometheus/assets/inputs/IconButton/v1/IconButton.svelte";
    import TabTrapHelper from "@itslearning/prometheus/lib/helpers/TabTrapHelper.js";
    import Link from "@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte";

    const dispatch = createEventDispatcher();

    /** @type {Element} */
    export let element = null;
    /** @type {'top'|'bottom'} */
    export let placement = "bottom";
    /** @type {boolean} */
    export let show = false;
    /** @type {string} */
    export let idLO = "";
    /** @type {string} */
    export let heading = "";
    /** @type {string} */
    export let text = "";
    /** @type {string} */
    export let closeButtonText = "";
    /** @type {boolean} */
    export let showActions = false;
    /** @type {string} */
    export let linkText = "";
    /** @type {string} */
    export let actionButtonText = "";
    /** @type {string} */
    export let actionButtonLabel = "";
    /** @type {string} */
    export let courseId = "";
    /** @type {boolean} */
    export let tableViewParent;

    const id = guidV4();
    const removeButtonId = `removeButton-${guidV4()}`;
    const closeButtonClass = "itsl-tooltipdialog__content-headingrow-closebutton";
    const role = "dialog";

    let tooltipdialog;
    let focusedBeforeTooltipDialog = undefined;
    let tabTrapper = undefined;

    $: displayClass = show ? "" : "itsl-tooltipdialog__hidden";

    $: if (show) {
        onOpen();
    } else {
        onClose();
    }

    $: destinationUrl = `/LearningObjective/AssessmentReport/SingleLearningObjectiveProgressReport.aspx?LearningObjectiveId=${idLO}&CourseID=${courseId}&ReturnUrl=${encodeURIComponent(
        window.location.href
    )}`;

    function onOpen() {
        focusedBeforeTooltipDialog = document.activeElement;
        updateTooltipDialog(show);

        setTimeout(() => {
            if (tooltipdialog) {
                // Enable the tab trap and set focus on close button
                tabTrapper = new TabTrapHelper(tooltipdialog);
                tabTrapper.init();
                tooltipdialog.querySelector(`.${closeButtonClass}`).focus();
            }
        }, 0);
    }

    function onClose() {
        // Return focus to the element that was focused before opening

        if (!tableViewParent) {
            if (focusedBeforeTooltipDialog) {
                focusedBeforeTooltipDialog.focus();
            }

            // Disable the tab trap
            if (tabTrapper) {
                tabTrapper.teardown();
            }
        }
    }

    function onWindowKeyUp(event) {
        const key = normalizeKey(event.key);

        if (key === Keys.ESCAPE) {
            dispatch("close", event);
        }
    }

    function clickHandler(event) {
        if (element && tooltipdialog) {
            if (!element.contains(event.target) && !tooltipdialog.contains(event.target)) {
                dispatch("close", event);
            }
        }
    }

    function onScrollChange() {
        updateTooltipDialogPosition();
    }

    function updateTooltipDialog(show) {
        if (show) {
            setTimeout(updateTooltipDialogPosition, 0);
        }
    }

    function updateTooltipDialogPosition() {
        if (element) {
            const rect = element.getBoundingClientRect();
            const elementInFirstHalfOfThePage = rect.top <= window.innerHeight / 2;
            const scrollAtTheBottom =
                window.scrollY + window.innerHeight + 200 > document.body.scrollHeight; // at the bottom of the page

            if (window.scrollY !== 0) {
                // there is a scrollbar
                if (scrollAtTheBottom) {
                    placement = "top";
                    tooltipdialog.style.left = element.getBoundingClientRect().left + "px";
                    tooltipdialog.style.top =
                        element.getBoundingClientRect().top -
                        tooltipdialog.offsetHeight -
                        10 +
                        "px";
                } else {
                    placement = "bottom";
                    tooltipdialog.style.left = element.getBoundingClientRect().left + "px";
                    tooltipdialog.style.top = element.getBoundingClientRect().top + 30 + "px";
                }
            } else {
                if (!elementInFirstHalfOfThePage) {
                    placement = "top";
                    tooltipdialog.style.left = element.getBoundingClientRect().left + "px";
                    tooltipdialog.style.top =
                        element.getBoundingClientRect().top -
                        tooltipdialog.offsetHeight -
                        10 +
                        "px";
                } else {
                    placement = "bottom";
                    tooltipdialog.style.left = element.getBoundingClientRect().left + "px";
                    tooltipdialog.style.top = element.getBoundingClientRect().top + 30 + "px";
                }
            }
        }
    }
</script>
