<div class="set-dates-modal">
    <Modal
        id="set-dates-modal-id"
        bind:enabled={showSetDatesModal}
        titleText={i18n.setDate}
        confirmText={i18n.save}
        cancelText={i18n.cancel}
        on:close={setDatesModalClicked}
    >
        <div slot="body">
            <h2 class="set-dates-modal-section__heading">{i18n.dateOfFirstPlan}</h2>
            <div class={dateTimePickerModalFromSection}>
                <DatePickerField
                    bind:value={dateValueFrom}
                    bind:expanded={datePickerFromExpanded}
                    firstDayOfWeek={config.firstDayOfWeek}
                    locale={config.locale}
                    id="date-picker-from"
                    label={i18n.datePickerLabelStart}
                    labelPosition="left"
                    placeholder={i18n.datePickerPlaceholder}
                    format={dateFormat}
                    closeOnSelect={true}
                    {i18n}
                    on:select={dataSelected}
                />
                {#if dateTimeSet && showTimeSelector}
                    <TimeSelector
                        hourLabel={i18n.hour}
                        minuteLabel={i18n.minute}
                        periodLabel=" "
                        {config}
                        bind:hours={hoursFrom}
                        bind:minutes={minutesFrom}
                        bind:isPM={isPMFrom}
                        bind:dateTimeSet
                        bind:isDisabled={isTimeSpanDisabled}
                    />
                {:else}
                    <Button
                        type="plain"
                        htmlType="button"
                        text={i18n.setTime}
                        on:click={toggleTimeSpanSetting}
                    />
                {/if}
            </div>
            <div class={dateTimePickerModalToSection}>
                <DatePickerField
                    bind:value={dateValueTo}
                    bind:expanded={datePickerToExpanded}
                    firstDayOfWeek={config.firstDayOfWeek}
                    locale={config.locale}
                    id="date-picker-to"
                    label={i18n.datePickerLabelStop}
                    labelPosition="left"
                    placeholder={i18n.datePickerPlaceholder}
                    format={dateFormat}
                    closeOnSelect={true}
                    {i18n}
                    on:select={dataSelected}
                />
                {#if dateTimeSet && showTimeSelector}
                    <TimeSelector
                        hourLabel={i18n.hour}
                        minuteLabel={i18n.minute}
                        periodLabel=" "
                        {config}
                        bind:hours={hoursTo}
                        bind:minutes={minutesTo}
                        bind:isPM={isPMTo}
                        bind:dateTimeSet
                        bind:isDisabled={isTimeSpanDisabled}
                    />
                {:else}
                    <Button
                        type="plain"
                        htmlType="button"
                        text={i18n.setTime}
                        on:click={toggleTimeSpanSetting}
                    />
                {/if}

                <SetDatesModalError
                    {showStartStopErrorMessage}
                    {setValidDatesErrorMessage}
                    {showStartInvalidMessage}
                    {showStopInvalidMessage}
                    {showRecurrenceErrorMessage}
                    {i18n}
                />
            </div>

            <h2 class="set-dates-modal-section__heading">{i18n.recurrence}</h2>
            {#if isRecurrenceSectionVisible}
                <div class="set-dates-modal-section__options">
                    <Select label={i18n.every} bind:value={weeksDropDownValue}>
                        {#each recurrenceArray.slice(weekCount) as recurrence}
                            <!-- The content of the dropdown changes according to the selected interval of dates -->
                            <option value={recurrence}>
                                {recurrence}
                            </option>
                        {/each}
                    </Select>
                    <span>{i18n.on}</span>
                </div>
                <div class="set-dates-modal-section__checkbox-group">
                    <!-- All checkboxes are disabled if the selected interval is more than a day. Otherwise only the checkbox for that weekday is disabled.-->
                    {#each daysArray as day}
                        {#if !day.disabled}
                            <!--Not disabled-->
                            <Checkbox
                                label={day.text}
                                bind:checked={day.checked}
                                disabled={day.disabled}
                                small
                            />
                        {:else}
                            <Checkbox
                                label={day.text}
                                checked={day.checked}
                                disabled={true}
                                small
                            />
                        {/if}
                    {/each}
                </div>
                {#if currentDayDiff > 0}
                    <div class="set-dates-modal-section__info">
                        {i18n.recurrence_BothDatesSelectedInfoMessage}
                    </div>
                {/if}
            {:else}
                <div class="set-dates-modal-section__info">
                    {i18n.recurrence_PlanDateNotSetWarning}
                </div>
            {/if}
        </div>
    </Modal>
</div>

<script>
    import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";
    import Checkbox from "@itslearning/prometheus/assets/inputs/Checkbox/v1/Checkbox.svelte";
    import DatePickerField from "@itslearning/prometheus/assets/inputs/DatePickerField/v1/DatePickerField.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import Select from "@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte";
    import TimeSelector from "../DateTimePicker/TimeSelector.svelte";
    import SetDatesModalError from "./SetDatesModalError.svelte";
    import config from "../../config";
    import {
        isDateOnlyMode,
        validateDateFormat,
        initAutoSuggestFromDate,
        initAutoSuggestToDate
    } from "../../helpers/dateHelper";
    import {
        defaultFromHour,
        defaultToHour,
        weekDays,
        dateTimePickerModalFromSection,
        dateTimePickerModalToSection,
        fromDatePickerInputField,
        toDatePickerInputField
    } from "../../constants";
    import { createEventDispatcher, onMount } from "svelte";

    export let showSetDatesModal;
    export let i18n;

    let dateValueFrom;
    let dateValueFromInitially;
    let datePickerFromExpanded = false;
    let dateValueTo;
    let dateValueToInitially;
    let datePickerToExpanded = false;

    // Default time selection will be 08-16 or 08 AM-04 PM if using 12h format
    let hoursFrom = defaultFromHour;
    let minutesFrom = "00";
    let isPMFrom = false;
    let hoursTo = defaultToHour;
    let minutesTo = "00";
    let isPMTo = false;

    let mountComplete = false;
    let showStartStopErrorMessage = false;
    let setValidDatesErrorMessage = false;
    let showStartInvalidMessage = false;
    let showStopInvalidMessage = false;
    let showTimeSelector = false;
    let dateTimeSet = false;
    let isTimeSpanDisabled = true;

    let weeksDropDownValue;
    let daysArray = [
        { const: weekDays.Sunday, text: i18n.sunday, checked: false, disabled: false },
        { const: weekDays.Monday, text: i18n.monday, checked: false, disabled: false },
        { const: weekDays.Tuesday, text: i18n.tuesday, checked: false, disabled: false },
        { const: weekDays.Wednesday, text: i18n.wednesday, checked: false, disabled: false },
        { const: weekDays.Thursday, text: i18n.thursday, checked: false, disabled: false },
        { const: weekDays.Friday, text: i18n.friday, checked: false, disabled: false },
        { const: weekDays.Saturday, text: i18n.saturday, checked: false, disabled: false }
    ];

    const recurrenceArray = [
        i18n.week,
        i18n.week2,
        i18n.week3,
        i18n.week4,
        i18n.week5,
        i18n.week6,
        i18n.week7,
        i18n.week8,
        i18n.week9,
        i18n.week10
    ];

    const dateFormat = config.currentDateFormat;

    const dispatch = createEventDispatcher();

    $: isTimeSpanDisabled = !showTimeSelector;

    $: isRecurrenceSectionVisible =
        dateValueFrom &&
        dateValueTo &&
        !showStartStopErrorMessage &&
        !showStartInvalidMessage &&
        !showStopInvalidMessage &&
        !showRecurrenceErrorMessage;

    $: currentDayDiff =
        dateValueFrom && dateValueTo
            ? Math.floor((dateValueTo - dateValueFrom) / (1000 * 60 * 60 * 24))
            : -1;

    $: weekCount = currentDayDiff !== -1 ? Math.floor(currentDayDiff / daysArray.length) : 0;

    $: showRecurrenceErrorMessage = currentDayDiff > 0 && weekCount >= 10 ? true : false; // Recurrence can be set only when the date interval is less than 10 weeks

    $: {
        const datePickerFrom = dateValueFrom ? new Date(dateValueFrom) : undefined;
        const datePickerTo = dateValueTo ? new Date(dateValueTo) : undefined;

        if (!datePickerFrom || !datePickerTo) {
            showStartStopErrorMessage = false;
        } else {
            insertHoursAndMinutes(datePickerFrom, datePickerTo);

            // Use epoch time to compare time (ms since 1970)
            const epochStart = datePickerFrom.valueOf();
            const epochStop = datePickerTo.valueOf();

            setValidDatesErrorMessage = false;
            showStartStopErrorMessage = epochStart > epochStop ? true : false;
        }
    }

    $: if (
        mountComplete &&
        dateValueFrom &&
        dateValueFromInitially !== dateValueFrom &&
        !datePickerFromExpanded
    ) {
        checkAutoSuggestToDate();
    }

    $: if (
        mountComplete &&
        dateValueTo &&
        dateValueToInitially !== dateValueTo &&
        !datePickerToExpanded
    ) {
        checkAutoSuggestFromDate();
    }

    onMount(() => {
        if (dateTimeSet === true && !isDateOnlyMode(parseInt(minutesFrom), parseInt(minutesTo))) {
            showTimeSelector = true;
            isTimeSpanDisabled = false;
        } else {
            showTimeSelector = false;
            isTimeSpanDisabled = true;
        }

        dateValueFromInitially = dateValueFrom;
        initAutoSuggestFromDate(dateValueFrom, dateValueTo, dateValueFromInitially);

        dateValueToInitially = dateValueTo;
        initAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially);

        // Decide daysarray order according to config.firstDayOfWeek
        daysArray = rotateArray(daysArray, config.firstDayOfWeek);

        mountComplete = true;
    });

    function checkAutoSuggestFromDate() {
        if (dateValueTo && !dateValueFromInitially) {
            dateValueFrom = dateValueTo;
            updateDaysArray(dateValueFrom);
        }

        dateValueFromInitially = dateValueFrom;
    }

    function checkAutoSuggestToDate() {
        const dateFrom = document.getElementById(fromDatePickerInputField).value;

        if (!validateDateFormat(dateFrom, config.currentDateFormat)) {
            return;
        }

        if (dateValueFrom && (!dateValueToInitially || dateValueFrom > dateValueTo)) {
            dateValueTo = dateValueFrom;
            updateDaysArray(dateValueTo);
        }

        dateValueToInitially = dateValueTo;
    }

    function rotateArray(array, position) {
        for (let i = 0; i < position; i++) {
            array.push(array.shift());
        }

        return array;
    }

    // Case 1: when clicking outside the DatePicker, the autosuggest picks the same date for both fields.
    // Case 2: first modal load, one date is selected, the other date is still undefined.
    // Action: we consider this case as if the same day is selected and update daysArray.
    function updateDaysArray(dateValue) {
        const datePicker = dateValue ? new Date(dateValue) : undefined;

        if (datePicker) {
            const checkedDay =
                (datePicker.getDay() - config.firstDayOfWeek + daysArray.length) % daysArray.length;

            if (checkedDay >= 0) {
                daysArray[checkedDay].checked = true;
                daysArray[checkedDay].disabled = true;
            }
        }

        daysArray = daysArray; // Needed to trigger the update in the checkboxes
        updateweeksDropDownValue();
    }

    // When the weeks dropdown updates without the user selecting an option, "weeksDropDownValue" needs to be updated too, otherwise it will always be "Week".
    function updateweeksDropDownValue() {
        if (currentDayDiff > 0) {
            // More than one day interval
            weeksDropDownValue = recurrenceArray.slice(weekCount)[0];
        }
    }

    function toggleTimeSpanSetting() {
        if (showTimeSelector === true) {
            showTimeSelector = false;
        } else {
            if (isDateOnlyMode(parseInt(minutesFrom), parseInt(minutesTo))) {
                hoursFrom = defaultFromHour;
                minutesFrom = "00";
                hoursTo = defaultToHour;
                minutesTo = "00";
            }

            showTimeSelector = true;
        }

        dateTimeSet = showTimeSelector;
    }

    function dataSelected() {
        // Filtering the week days included in the selected interval. These days will be checked and disabled.
        const datePickerFrom = dateValueFrom ? new Date(dateValueFrom) : undefined;
        const datePickerTo = dateValueTo ? new Date(dateValueTo) : undefined;

        // Reset
        daysArray.map(d => (d.checked = false));
        daysArray.map(d => (d.disabled = false));

        if (!datePickerFrom || !datePickerTo) {
            // First modal load, one date is selected, the other date is still undefined, so we consider this case as if the same day is selected.
            if (datePickerFrom) updateDaysArray(datePickerFrom);
            if (datePickerTo) updateDaysArray(datePickerTo);
        }

        if (datePickerFrom && datePickerTo) {
            // Same logic as old planner
            const startDay =
                (datePickerFrom.getDay() - config.firstDayOfWeek + daysArray.length) %
                daysArray.length;
            const stopDay =
                (datePickerTo.getDay() - config.firstDayOfWeek + daysArray.length) %
                daysArray.length;

            if (isTheSameDay(datePickerFrom, datePickerTo)) {
                daysArray[startDay].checked = true;
                daysArray[startDay].disabled = true;
            } else {
                if (
                    currentDayDiff >= daysArray.length ||
                    (currentDayDiff === daysArray.length - 1 && startDay === stopDay)
                ) {
                    daysArray.map(d => (d.checked = true));
                } else {
                    if (startDay < stopDay) {
                        for (let i = startDay; i <= stopDay; i++) {
                            daysArray[i].checked = true;
                        }
                    }

                    if (startDay > stopDay) {
                        for (let i = 0; i <= stopDay; i++) {
                            daysArray[i].checked = true;
                        }

                        for (let i = startDay; i < daysArray.length; i++) {
                            daysArray[i].checked = true;
                        }
                    }
                }

                daysArray.map(d => (d.disabled = true));
            }
        }

        daysArray = daysArray; // Needed to trigger the update in the checkboxes
        updateweeksDropDownValue();
    }

    function isTheSameDay(start, stop) {
        return (
            start &&
            stop &&
            start.getFullYear() === stop.getFullYear() &&
            start.getMonth() === stop.getMonth() &&
            start.getDate() === stop.getDate()
        );
    }

    function insertHoursAndMinutes(datePickerFrom, datePickerTo) {
        if (showTimeSelector) {
            let hoursFromVal = parseInt(hoursFrom);

            if (config.useTwelveHourFormat) {
                if (hoursFromVal === 12) {
                    hoursFromVal = 0;
                }

                if (isPMFrom) {
                    hoursFromVal = hoursFromVal + 12;
                }
            }

            datePickerFrom.setHours(hoursFromVal, parseInt(minutesFrom));

            let hoursToVal = parseInt(hoursTo);

            if (config.useTwelveHourFormat) {
                if (hoursToVal === 12) {
                    hoursToVal = 0;
                }

                if (isPMTo) {
                    hoursToVal = hoursToVal + 12;
                }
            }

            datePickerTo.setHours(hoursToVal, parseInt(minutesTo));
        } else {
            datePickerFrom.setHours(0, 1);
            datePickerTo.setHours(23, 59);
        }
    }

    function setDatesModalClicked(event) {
        if (event.detail.confirmed === true) {
            if (!dateValueFrom || !dateValueTo) {
                setValidDatesErrorMessage = true;
                showSetDatesModal = true;
            } else {
                setValidDatesErrorMessage = false;

                const datePickerFrom = new Date(dateValueFrom);
                const datePickerTo = new Date(dateValueTo);

                const dateFrom = document.getElementById(fromDatePickerInputField).value;
                const dateTo = document.getElementById(toDatePickerInputField).value;

                const validateDateToFormat = validateDateFormat(dateTo, dateFormat);
                const validateDateFromFormat = validateDateFormat(dateFrom, dateFormat);

                if (!validateDateFromFormat) {
                    showStartInvalidMessage = true;
                    showStopInvalidMessage = false;

                    return;
                }

                if (!validateDateToFormat) {
                    showStopInvalidMessage = true;
                    showStartInvalidMessage = false;

                    return;
                }

                insertHoursAndMinutes(datePickerFrom, datePickerTo);

                // Use epoch time to compare time (ms since 1970)
                const epochStart = datePickerFrom.valueOf();
                const epochStop = datePickerTo.valueOf();

                showStartStopErrorMessage = epochStart > epochStop ? true : false; // We need to check that the selected time is valid as well

                if (showStartStopErrorMessage || showRecurrenceErrorMessage) {
                    showSetDatesModal = true;
                }

                if (!showStartStopErrorMessage && !showRecurrenceErrorMessage) {
                    const checkedDays = daysArray.filter(d => d.checked);

                    dispatch("setDates", {
                        start: datePickerFrom,
                        stop: datePickerTo,
                        weekDaysAPI: checkedDays.map(d => d.const),
                        weekNumber: recurrenceArray.indexOf(weeksDropDownValue) + 1 // The existing endpoint requires numbers to start from 1
                    });
                }
            }
        } else {
            dispatch("close");
        }
    }
</script>
