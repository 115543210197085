import { onePlanAtATimePlanStatus } from "../constants";

export const isPlanCompletedHelper = (plan) => {
    let isPlanCompleted = plan.planStatus === onePlanAtATimePlanStatus.completed;

    if(!isPlanCompleted && plan.elements?.some(e => !e.isFolder)) {
        isPlanCompleted = plan.elements.every(e => e.isFolder || e.isCompleted);
    }

    return isPlanCompleted;
};
