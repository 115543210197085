export const makeCourseForDropdown = (course, actionSource, archivedText) => {
    const courseTitle = !course.Status
        ? course.CourseTitle
        : course.CourseTitle + " (" + archivedText + ")";

    return {
        icon: "",
        label: courseTitle,
        link: false,
        target: "_self",
        value: {
            source: actionSource,
            title: courseTitle,
            id: course.CourseId,
            hasUseTopics: course.HasCourseSettingUseTopics
        }
    };
};
