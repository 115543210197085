<IconButton
    {...$$restProps}
    type="plain"
    htmlType="button"
    iconPlacement="start"
    iconClass={`its-plans__${type}-icon`}
    text={text}
    on:click
/>

<script>
    import IconButton from "@itslearning/prometheus/assets/inputs/IconButton/v1/IconButton.svelte";

    /** @type {string} */
    export let text;

    /** @type {'add'|'change'|'delete'} */
    export let type;
</script>
