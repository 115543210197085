import { getCourseTerms } from "../../api";

export class TermsDataProvider {
    constructor() {
        this.options = [];
    }

    async populateOptions() {
        const terms = await getCourseTerms();

        this.options = terms.map(({ TermId, Name }) => ({
            label: Name,
            value: TermId,
        }));
        this.options.sort((a, b) => a.value - b.value);

        return this.options;
    }

    async getOptions(searchText = undefined) {
        if (searchText === undefined) {
            return this.options;
        }

        return this.options.filter(({ label }) => label.toLowerCase().includes(searchText.toLowerCase()));
    }

    getNameById(id) {
        const resultItem = this.options.find(t => t.value === id);

        return resultItem ? resultItem.label : undefined;
    }
}
