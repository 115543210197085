<div
    class="itsl-plans-rich-text-field"
    class:itsl-plans-rich-text-field--can-edit={config.canEdit && !isValueBeingUpdated}
    class:itsl-plans-rich-text-field--is-being-updated={isValueBeingUpdated}
>
    {#if config.canEdit && (!value || showTextArea)}
        <div
            class="itsl-plans-rich-text-field__text-area-wrapper"
            class:itsl-plans-rich-text-field__text-area-wrapper--is-being-updated={isValueBeingUpdated}
        >
            <textarea
                aria-labelledby={labelledBy}
                aria-label={!labelledBy ? title : null}
                class="itsl-plans-rich-text-field__text-area"
                class:itsl-plans-rich-text-field__text-area--is-being-updated={isValueBeingUpdated}
                readonly={isValueBeingUpdated}
                {value}
                bind:this={textArea}
                on:input={onTextAreaInput}
                on:blur={handleInputBlur}
                {placeholder}
            />
        </div>
    {:else}
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <div
            aria-labelledby={labelledBy}
            aria-label={!labelledBy ? title : null}
            role="group"
            tabindex="0"
            class="itsl-plans-rich-text-field__html-content"
            on:click={onClickEdit}
            on:keydown={onEditorKeyDown}
        >
            {#if displayValue && displayValue.length}
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html displayValue}
            {:else}
                {placeholder}
            {/if}
        </div>
    {/if}

    {#if config.canEdit}
        {#if !hideEditButton}
            <button
                type="button"
                class="itsl-plans-rich-text-field__edit-button"
                disabled={isValueBeingUpdated}
                on:click={onClickEdit}
                aria-describedby={labelledBy}
            >
                <span class="screen-reader">{i18n.editRichText}</span>
            </button>
        {/if}

        <Modal
            confirmText={i18n.save}
            cancelText={i18n.cancel}
            bind:enabled={editModalEnabled}
            on:close={onModalClosed}
            clickOutsideCloses={false}
        >
            <span slot="title">{title}</span>
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div slot="body" on:keydown={keyDownWrapper}>
                {#if editModalLoading}
                    <div class="itsl-plans-rich-text-field__edit-loading"></div>
                {/if}
                <div
                    class:itsl-plans-rich-text-field__content-loading={editModalLoading}
                    bind:this={richTextContent}
                >
                    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                    {@html richTextEditorValue}
                </div>
            </div>
        </Modal>
    {/if}
    <span aria-live="polite" role="status" class="screen-reader">
        {editModalLoading ? i18n.loading : i18n.loaded}
    </span>
</div>

<script>
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";

    import { onMount, tick } from "svelte";
    import { Keys } from "@itslearning/atlas/keyboard/keys";
    import { normalizeKey } from "@itslearning/atlas/keyboard/normalize";

    export let config;
    export let i18n;
    export let value;
    export let displayValue;
    export let labelledBy;
    export let title;
    export let placeholder = "";
    export let safeHtmlProvider = html => Promise.resolve({ value: html });
    export let showTextArea = true;
    export let hideEditButton = false;

    let textArea;
    let richTextContent;
    let editorInstance;
    let ckEditorGlobal;
    let ckScript;
    let editModalEnabled = false;
    let editModalLoading = false;
    let isValueBeingUpdated = false;

    onMount(() => setTimeout(textAreaInputResize, 0));

    $: richTextEditorValue = showTextArea ? replaceLineBreaksWithBrTags(htmlEncode(value)) : value;

    const onTextAreaInput = () => textAreaInputResize();

    const textAreaInputResize = () => {
        if (textArea) {
            textArea.style.height = `${textArea.scrollHeight}px`;
        }
    };

    const onClickEdit = async () => {
        if (!config.canEdit || isValueBeingUpdated) {
            return;
        }

        if (textArea && value != textArea.value) {
            await handleInputBlur();
        }

        editModalEnabled = true;
        editModalLoading = true;

        ckEditorGlobal = await loadCkScript();

        setTimeout(() => {
            // It's necessary to define the jquery global because the ckEditor config includes a call to $
            // which fails without it. It doesn't do anything though.
            window["$"] = () => {};

            editorInstance = ckEditorGlobal.replace(richTextContent, {
                customConfig: `/restapi/ckeditor/configuration/v1?RenderCommonScriptPart=1&Toolbar=${config.editorToolbarType}&LocationId=${config.courseId}&LocationType=${config.locationType}&EnableSlideshow=0&EnableAutoGrow=0&ShowTreeLinkButton=1&StoreImagesWithContent=1`
            });

            editModalLoading = false;
        }, 0);
    };

    const onModalClosed = async event => {
        editorInstance.commands["a11ychecker.close"] &&
            editorInstance.commands["a11ychecker.close"].exec();

        if (event.detail.confirmed) {
            const editorHtml = editorInstance.getData();
            // This is required to prevent redundnat line breakes - see Itsolutions.Itslearning.Web.UI.Controls.Editor.CKEditor
            const newValue = editorHtml
                ? editorHtml.replace(/\t/g, " ").replace(/\n\n/g, "\n")
                : "";

            await updateHtml(newValue);
            await tick();
            textAreaInputResize();
        }
    };

    const loadCkScript = () =>
        new Promise(resolve => {
            if (window["CKEDITOR"]) {
                resolve(window["CKEDITOR"]);
            }

            ckScript = document.createElement("script");

            ckScript.onload = () => resolve(window["CKEDITOR"]);
            ckScript.setAttribute("src", config.richTextEditorBasePath + "/ckeditor.js?t=N8K3");

            document.getElementsByTagName("head").item(0).appendChild(ckScript);
        });

    const keyDownWrapper = event => {
        if (editorInstance) {
            const key = normalizeKey(event.key);

            if (key !== Keys.TAB && key !== Keys.ESCAPE) {
                editorInstance.focus();
            }
        }
    };

    const onEditorKeyDown = async event => {
        const key = normalizeKey(event.key);

        if (key === Keys.ENTER) {
            await onClickEdit();
        }
    };

    const handleInputBlur = async () => {
        if (textArea.value === value) {
            return;
        }

        await updateHtml(htmlEncode(textArea.value));
        await tick();
        textAreaInputResize();
    };

    const updateHtml = async newValue => {
        if (!showTextArea && areValuesTheSame(newValue)) {
            return;
        }

        try {
            isValueBeingUpdated = true;

            const {
                Value: updatedValue,
                SafeHtml: updatedDisplayValue,
                CustomData: { isPlainText }
            } = await safeHtmlProvider(newValue);

            value = updatedValue;
            displayValue = updatedDisplayValue;
            showTextArea = isPlainText;
        } finally {
            isValueBeingUpdated = false;
        }
    };

    const areValuesTheSame = newValue => {
        return newValue.trimEnd() === value && value.trimEnd();
    };

    export const replaceLineBreaksWithBrTags = valueToChange => {
        if (valueToChange === null || valueToChange === undefined) {
            return valueToChange;
        }

        // This corresponds to replaceLineBreaksWithBrTags from Common.ts file from common controls.
        const lineBreakToBrRegex = /\r?\n/g;
        const brTagString = "<br />";

        return valueToChange.replace(lineBreakToBrRegex, brTagString);
    };

    const htmlEncode = text => {
        const textArea = document.createElement("textarea");

        textArea.innerText = text;

        return textArea.innerHTML.split("<br>").join("\n");
    };

    export function edit() {
        onClickEdit();
    }
</script>
