<button
    id={LO_ID}
    class="plan__learning-objective"
    aria-haspopup="dialog"
    bind:this={element}
    on:click={!isTooltipDialogOpened ? openTooltipDialog : closeTooltipDialog}
>
    <span class="lo-icon {isFolder ? 'lo-icon--folder' : ''}"></span>
    <span class="plan__learning-objective-title">{title}</span>
</button>

<script>
    import { createEventDispatcher } from "svelte";
    import { guidV4 } from "@itslearning/atlas/generate/guid";

    const dispatch = createEventDispatcher();

    export let id;
    export let title;
    export let description;
    export let isFolder;
    export let isTooltipDialogOpened = false;

    const LO_ID = `lo-${guidV4()}`;

    let element = null;
    let tooltipDialogPlacement;

    $: element;

    // Show the tooltip dialog
    function openTooltipDialog() {
        dispatch("openTooltipDialog", {
            learningObjectiveElement: element,
            id: id,
            heading: title,
            text: description,
            placement: tooltipDialogPlacement
        });
    }

    // Close the tooltip dialog
    function closeTooltipDialog(event) {
        event.stopPropagation();
        dispatch("closeTooltipDialog");
    }
</script>
