<div class="add-term-modal">
    {#if isEmpty}
    <EmptyStateModal
        {dispatch}
        {enabled}
        on:close
        on:cancel
    />
    {:else}
    <Modal
        id={addTermModalId}
        {enabled}
        confirmText={confirmText}
        cancelText={i18n.cancel}
        titleText={titleText}
        clickOutsideCloses={false}
        on:close
        on:cancel
        on:confirm={handleConfirm}
    >
        <p slot="body">
            <Combobox
                id={comboboxId}
                cssClass="its-add-term-modal-terms-combobox"
                label={termTitle}
                required
                bind:selected={selectedTermId}
                enableAutoComplete
                dataProvider={provider}
            />
            <span class="its-add-term-modal-spacer"></span>
        </p>
    </Modal>
    {/if}
</div>

<script>
    import { createEventDispatcher, onMount, tick } from "svelte";
    import { formatLanguageString } from '@itslearning/atlas/strings/format.js';
    import Combobox from "@itslearning/prometheus/assets/inputs/Combobox/v1/Combobox.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import EmptyStateModal from "@its-ui/planner-common/components/AddTermModal/AddTermModalEmptyState.svelte";
    import i18n from "../../i18n";
    import { TermsDataProvider } from "./TermsDataProvider";

    export let enabled = false;
    export let selectedTermId;
    export let termTitle;

    /** @type {'add'|'change'} */
    export let type = 'add';

    let isEmpty = true;

    const addTermModalId = "add-term-modal-id";
    const comboboxId = "add-term-modal-terms-combobox-id";
    const dispatch = createEventDispatcher();
    const provider = new TermsDataProvider();

    onMount(() => {
        provider.populateOptions()
            .finally(e => isEmpty = provider.options.length == 0);
    });

    $: isAdd = type == 'add';
    $: isChange = type == 'change';

    $: confirmText = isAdd ? i18n.add : isChange ? i18n.change : "";
    $: titleText = isAdd
        ? formatLanguageString(i18n.addWithPlaceholder, termTitle)
        : isChange
            ? formatLanguageString(i18n.changeWithPlaceholder, termTitle)
            : "";
    
    $: {
        if (!isEmpty && enabled) {
            addComboboxPlaceholder();
            toggleAddButton(!Boolean(selectedTermId));
        }
    }

    const toggleAddButton = async (disabled) => {
        await tick();
        const addButton = document.querySelector(`#${addTermModalId} .prom-modal2__footer .prom-button__primary`);
        addButton.disabled = disabled;
    };

    const addComboboxPlaceholder = async () => {
        await tick();
        const combobox = document.querySelector(`#${comboboxId}`);
        combobox.placeholder = i18n.select;
    };

    const handleConfirm = () => dispatch("confirm", { termId: selectedTermId });
</script>
