<svelte:window on:beforeunload={onBeforeUnload} />
{#if showCustomFields && customFieldsVisible.some(a => !!a)}
    <div class="its-plans__topic-fields">
        {#each topic.customFields as field, i}
            {#if showCustomField(i)}
                {#if field.type !== columnTypes.Topic && customFieldsVisible[i]}
                    <div class="its-plans__topic-field">
                        <h2 class="its-plans__topic-field-label" id="field-title-{field.id}">
                            {field.title}
                        </h2>
                        {#if field.type === columnTypes.RichText}
                            <TextField
                                bind:field
                                bind:this={fieldElements[i]}
                                {config}
                                {topic}
                                {i18n}
                            />
                        {:else if field.type === columnTypes.LearningObjectives}
                            <LearningObjectives
                                bind:field
                                bind:this={fieldElements[i]}
                                canEdit={config.canEdit}
                                {config}
                                {topic}
                                {i18n}
                            />
                        {:else if field.type === columnTypes.Term}
                            <TopicTerm
                                bind:field
                                canEdit={config.canEdit}
                                {topic}
                                {i18n}
                            />
                        {:else if field.type === columnTypes.Rubric}
                            <TopicRubric canEdit={config.canEdit} {topic} {i18n} {config} />
                        {/if}
                    </div>
                {/if}
            {/if}
        {/each}
    </div>
{/if}

<script>
    import { columnTypes, columnVisibility } from "@its-ui/planner-common/constants";
    import TextField from "../TextField/TextField.svelte";
    import TopicTerm from "../TopicTerm/TopicTerm.svelte";
    import LearningObjectives from "../LearningObjectives/LearningObjectives.svelte";
    import TopicRubric from "../TopicRubric/TopicRubric.svelte";

    export let topic;
    export let i18n;
    export let config;

    const fieldElements = [];

    $: showCustomFields =
        topic &&
        topic.customFields &&
        topic.customFields.some(
            f => f.type !== columnTypes.Topic && f.type !== columnTypes.TopicThumbnail
        );

    $: customFieldsVisible =
        showCustomFields &&
        topic.customFields.map(field => {
            if (
                (field.type === columnTypes.Rubric && !config.isRubricForTopicEnabled) ||
                field.type === columnTypes.TopicThumbnail
            ) {
                return false;
            }

            if (config.canEdit) {
                return true;
            }

            if (field.type === columnTypes.RichText) {
                return field.text && field.text.length;
            }

            if (field.type === columnTypes.LearningObjectives) {
                return field.learningObjectives && field.learningObjectives.length;
            }

            if (field.type === columnTypes.Term) {
                return !!field.term;
            }

            if (field.type === columnTypes.Rubric) {
                return field.learningObjectives && field.learningObjectives.length;
            }
        });

    // Fired when the window is being closed or the user is navigating away
    function onBeforeUnload(event) {
        // Check if any of the fields have `isDirty` defined and if they return true prevent uoloading
        if (fieldElements.some(e => e && e.isDirty && e.isDirty())) {
            // Prompt the user there may be unsaved changes
            event.preventDefault();
            event.returnValue = "";
        }
    }

    /**
     * When to show when there is one field with visibility set for all or teacher.
     * @returns {boolean} `true` it shows topic field, `false` does not show.
     */
    function showCustomField(index) {
        return (
            ((topic.customFields[index].columnVisibility === columnVisibility.All ||
                topic.customFields[index].columnVisibility === columnVisibility.Teacher) &&
                topic.customFields[index].type !== columnTypes.Term) ||
            (topic.customFields[index].type === columnTypes.Term &&
                config.isTermInTopicsVisible === true)
        );
    }

    /**
     * When to show topic fields section.
     * @returns {boolean} `true` it shows topic fields section, `false` does not show.
     */
    function showCustomFieldSection() {
        return (
            topic.customFields.length === 2 &&
            topic.customFields[0].type === columnTypes.Topic &&
            topic.customFields[1].type === columnTypes.Term &&
            config.isTermInTopicsVisible === false
        );
    }
</script>
