{#if enabled}
<Modal
    id={modalId}
    closeable={false}
    hideHeader={true}
    role="dialog"
>
    <div slot="body">
        <EmptyState
            buttonHtmlType="button"
            {buttonText}
            description={i18n.topicTermEmptyStateDescription}
            headingLevel="h2"
            iconName="search-no-result"
            showButton={true}
            title={i18n.topicTermEmptyStateTitle}
            on:click={handleClick}
        >
        </EmptyState>
    </div>
</Modal>
{/if}

<script>
    import { tick } from "svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte";
    import EmptyState from "@itslearning/prometheus/assets/nodes/EmptyState/v1/EmptyState.svelte";
    import i18n from "../../i18n";

    export let buttonText = i18n.cancel;
    export let dispatch;
    export let enabled = false;

    let modalId = "add-term-modal-empty-state-id";

    $: {
        enabled && makeButtonSecondary();
    };

    const makeButtonSecondary = async () => {
        await tick();
        const button = document.querySelector(`#${modalId}__body button`);
        button.classList.add('prom-button__secondary');
    };

    const handleClick = () => dispatch("close");
</script>
